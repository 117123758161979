@fontFamily: CrimsonText-Italic;

.content {
  background: var(--foreground-rgb);
  margin-top: 50px;
  padding: 30px 24px;

  > h2 {
    margin-bottom: 50px;

    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--app-text-color);
    font-family: @fontFamily;
  }

  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 24px;
    row-gap: 24px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      z-index: 9;
      display: inline-block;
      border-radius: 28px;
      background: #272429;
      padding: 32px 24px;
      text-align: center;

      > .title {
        margin-top: 34px;
        font-weight: bold;
        font-size: 19px;
        min-height: 50px;
        color: var(--app-text-color);
      }

      > .desc {
        margin-top: 20px;
        text-align: left;
        font-weight: 400;
        text-wrap: balance;
        font-size: 12px;
        color: var(--app-text-60-color);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    .container {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;

      > .cardContainer {
        padding: 24px;

        > .title {
          margin-top: 15px;
        }

        > .desc {
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    padding: 10px;
  }
}

